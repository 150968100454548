<template>
	<v-layout fill-height>
		<v-list>
			<v-list-tile>
				<v-layout row justify-center align-center>
					<v-spacer />
					<v-btn icon flat @click="hideSideBar">
						<v-icon color="primary" x-large>close</v-icon>
					</v-btn>
				</v-layout>
			</v-list-tile>
			<RenameButton v-if="!isReadonly && conditionalDisplay.isMobileApp" v-model="currentDocument" list-tile-display />
			<ValidationButtons v-if="!isReadonly && !conditionalDisplay.isMobileApp"  v-model="currentDocument" in-viewer list-tile-display />
			<RotateDocumentButtons v-if="!isReadonly" v-model="currentDocument" list-tile-display />
			<PrintButton v-if="!conditionalDisplay.isMobileApp" v-model="currentDocument" list-tile-display :version="version" />
			<UniqueNumberButton v-if="!conditionalDisplay.isMobileApp" v-model="currentDocument" list-tile-display />
			<DownloadButton v-model="currentDocument" list-tile-display :version="version" />
		</v-list>
	</v-layout>
</template>

<script>
import PreviewAbstractBarVue from '@/components/Documents/Preview/PreviewAbstractBar'

export default {
	name: 'PreviewSideBar',
	components: {
		DownloadButton: () => ({
			component: import('@/components/Documents/Actions/Download/DownloadButton')
		}),
		RotateDocumentButtons: () => ({
			component: import('@/components/Documents/Actions/Rotate/RotateDocumentButtons')
		}),
		ValidationButtons: () => ({
			component: import('@/components/Documents/Actions/Validation/ValidationButtons')
		}),
		PrintButton: () => ({
			component: import('@/components/Documents/Actions/Print/PrintButton')
		}),
		UniqueNumberButton: () => ({
			component: import('@/components/Documents/Actions/UniqueNumber/UniqueNumberButton')
		}),
		RenameButton: () => ({
			component: import('@/components/Documents/Actions/Rename/RenameButton')
		}),
	},
	extends: PreviewAbstractBarVue,
	data: function () {
		return {
			eventsActionsMapping: []
		}
	},
	methods: {
		closeMe: function () {
			this.$emit('close')
		}
	}
}
</script>
